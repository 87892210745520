import React, { useEffect, useState } from 'react';
import StudentNavbar from "./StudentNavbar";
import "../../css/global-styles.css";
import axios from 'axios';
import { useUser } from '../../UserContext';
import qs from 'qs';
import { Doughnut, Bar } from 'react-chartjs-2'; // Import both Pie and Bar
import { Chart, PieController, ArcElement, Tooltip, Legend, BarController, CategoryScale, LinearScale, BarElement } from 'chart.js';

Chart.register(PieController, ArcElement, Tooltip, Legend, BarController, CategoryScale, LinearScale, BarElement);


function Grades() {
  const [tab, setTab] = useState(0);
  const [exams, setExams] = useState([]);
  const [error, setError] = useState('');
  const [stats, setStats] = useState(null);
  const [selectedExamId, setSelectedExamId] = useState(null);
  const { userId } = useUser();  // Use the useUser hook to get the current user ID
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [barChartData, setBarChartData] = useState({ labels: [], datasets: [] });

  // ...
  const handleExpandClick = (examId) => {
    fetchExamStats(examId);
    // You can use selectedExamId to fetch or display additional data for the selected exam
  };
  const [histogramData, setHistogramData] = useState({ labels: [], datasets: [] });
  const fetchExamStats = async (examId) => {
    try {
      const response = await axios.post('https://axs2459.uta.cloud/getExamStats.php', qs.stringify({ exam_id: examId }));
      setStats(response.data);
      const { mean, lowest, highest, total_marks } = response.data;
      const userMark = exams.find(e => e.exam_id === examId).score;
      const t = exams.find(e => e.exam_id === examId).total_marks;

      const userMarkPercentage = (userMark / t) * 100;
      let userMarkColor;
      if (userMarkPercentage >= 80) {
        userMarkColor = 'rgba(124, 255, 0, 1)';  // Green for high score
      } else if (userMarkPercentage >= 50) {
        userMarkColor = 'rgba(155, 255, 0, 0.2)';  // Yellow for medium score
      } else {
        userMarkColor = 'rgba(225, 0, 0, 0.2)';  // Red for low score
      }

      setChartData({
        labels: ['Your Mark', 'Total Marks'],
        datasets: [{
          data: [userMark, t],
          backgroundColor: [userMarkColor, 'rgba(200, 200, 200, 0.2)'],
          borderColor: ['rgba(75, 192, 192, 1)', 'rgba(200, 200, 200, 1)'],
          borderWidth: 1,
        }]
      });
      const scoresResponse = await axios.post('https://axs2459.uta.cloud/getAllScores.php', qs.stringify({ exam_id: examId }));
      const scores = scoresResponse.data.map(scoreData => scoreData.score);
      const bins = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]; // You can adjust these ranges
      const histogram = Array(bins.length - 1).fill(0);

      scores.forEach(score => {
        for (let i = 0; i < bins.length - 1; i++) {
          if (score >= bins[i] && score < bins[i + 1]) {
            histogram[i]++;
            break;
          }
        }
      });

      setHistogramData({
        labels: bins.slice(0, -1).map((b, i) => `${b}-${bins[i + 1]}`),
        datasets: [{
          label: 'Number of Students',
          data: histogram,
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        }]
      });
      // Set data for Bar Chart
      setBarChartData({
        labels: ['Mean', 'Highest Mark', 'Lowest Mark', 'Your Mark'],
        datasets: [{
          label: 'Scores',
          data: [mean, highest, lowest, userMark],
          backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(75, 192, 192, 0.5)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
          borderWidth: 1,
        }]
      });


      setShowChart(true);
    } catch (error) {
      console.error("Error fetching exam stats:", error);
    }
  };
  useEffect(() => {
    const data = qs.stringify({ student_id: userId });
    const config = {
      method: 'post',
      url: 'https://axs2459.uta.cloud/getexams.php',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then(response => {
        console.log("Response data:", response.data);
        setExams(response.data);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
        setError('Error fetching data');
      });
  }, [userId]);

  function wrapWithHeaders(content) {
    return (
      <table className="grades-table">
        <thead>
          <tr className="grades-table-row">
            <th className="grades-table-header">ID</th>
            <th className="grades-table-header">Score</th>
            <th className="grades-table-header">Due</th>
            <th className="grades-table-header"></th>
          </tr>
        </thead>
        {content}
      </table>
    );
  }

  function getTabView() {
    switch (tab) {
      case 1:
        return wrapWithHeaders(
          <tbody>
            {exams.map((exam, index) => (
              <tr className="grades-table-row" key={index}>
                <td>{`Exam-${exam.exam_id}`}</td>
                <td>{`${exam.score}/${exam.total_marks}`}</td>
                <td>{exam.date}</td>


                <td>
                  <button onClick={() => handleExpandClick(exam.exam_id)}>
                    expand
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        );
      case 2:
        return wrapWithHeaders(
          <tbody>
            {exams.map((exam, index) => (
              <tr className="grades-table-row" key={index}>
                <td>{`Exam-${exam.exam_id}`}</td>
                <td>{`${exam.score}/${exam.total_marks}`}</td>
                <td>{exam.date}</td>


                <td>
                  <button onClick={() => handleExpandClick(exam.exam_id)}>
                    expand
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        );
      default:
        return wrapWithHeaders(
          <tbody>
            {exams.map((exam, index) => (
              <tr className="grades-table-row" key={index}>
                <td>{`Exam-${exam.exam_id}`}</td>
                <td>{`${exam.score}/${exam.total_marks}`}</td>
                <td>{exam.date}</td>


                <td>
                  <button style={{ backgroundColor: 'green', borderRadius: '12px' }} type="submit" onClick={() => handleExpandClick(exam.exam_id)}>
                    expand
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        );
    }
  }

  return (
    <div className="page-container">
      <div className="c1main-container">
        <StudentNavbar
          wrapperClass="c1left-navbar"
          menuItemClass="c1nav-button"
        />
        <StudentNavbar
          wrapperClass="c1right-navbar"
          menuItemClass="c1nav-button2"
          isSubMenu
        />


        <div className="grades-container">
          
          <div className="grades-navbar">
            <button className="button" onClick={() => setTab(0)}>
              All
            </button>
            <button className="button" onClick={() => setTab(1)}>
              Assignments
            </button>
            <button className="button" onClick={() => setTab(2)}>
              Exams
            </button>
          </div>
          {getTabView()}
          <br />
          <br />
          {showChart && (
            <div className="chartsholder">
              {/* <Doughnut data={chartData} />  */}
              <div className="chart-container">
                <Bar data={histogramData} options={{ maintainAspectRatio: false }} />
              </div>
              <br /><br />
              <div className="chart-container">
                <Bar data={barChartData}
                  options={{
                    scales: {
                      x: {
                        type: 'category',  // explicitly set the type
                        // other options...
                      },
                      // ...
                    },
                  }}
                />
              </div>

            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Grades;