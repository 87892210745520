// import React from 'react';
// import ChatMessages from './ChatMessages';

// function ChatContent({ selectedRole, usersWithRole, selectedUser, formData, handleRoleChange, handleSelectedUser, handleFormChange, handleSubmit, chatRecord, userId }) {
//   return (
//     <div className="c1content">
//       <div className="searchcontent">
//         <form className="feedback-form" onSubmit={handleSubmit}>
//           <input type="hidden" name="side" value="student" />
//           <fieldset id="info">
//             <legend>CHAT</legend>
//             <select
//               id="description"
//               name="role"
//               className="chatdropdown"
//               value={selectedRole}
//               onChange={handleRoleChange}
//             >
//               <option value="student">student</option>
//               <option value="instructor">Instructor</option>
//               <option value="admin">Admin</option>
//               <option value="qa">QA</option>
//               <option value="pc">PC</option>

//             </select>
//             <br />
//             <br />
//             <select name="receiver" onChange={handleSelectedUser}>
//               {usersWithRole.map((result) => (
//                 <option value={result.id} key={result.id}>
//                   {result.fname}
//                 </option>
//               ))}
//             </select>
//             <br />
//             <br />
//             <textarea
//               id="description"
//               rows={4}
//               cols={50}
//               name="message"
//               placeholder="Message..."
//               value={formData.message}
//               onChange={handleFormChange}
//             ></textarea>
//             <br />
//             <br />
//             <button type="submit">Submit</button>
//             <br />
//             <br />
//           </fieldset>
//         </form>
//       </div>
//       <br />
//       <br />
//       <ChatMessages chatRecord={chatRecord} userId={userId} senderName={userId} receiverName={selectedUser}/>
//     </div>
//   );
// }

// export default ChatContent;

import React, { useEffect } from 'react';
import ChatMessages from './ChatMessages';
import axios from 'axios';

function ChatContent({ selectedRole, usersWithRole, selectedUser, formData, handleRoleChange, handleSelectedUser, handleFormChange, handleSubmit, chatRecord, userId ,setChatRecord}) {

  useEffect(() => {
    // Check if a user is selected
    console.log(selectedRole, usersWithRole, selectedUser)
    if (selectedUser) {
      // Make a POST request to fetch_chat_history.php with sender and receiver parameters in the request body
      axios
        .post('https://axs2459.uta.cloud/fetch_chat_history.php', {
          sender_id: userId,
          receiver_id: selectedUser,
        })
        .then(response => {
          // Handle the response, e.g., update the chatRecord state with the fetched data
          console.log('Chat history fetched:', response.data);
          setChatRecord(response.data.chat_messages);

        })
        .catch(error => {
          console.error('Error fetching chat history:', error);
        });
    }
  }, [selectedUser, userId]); // Run the effect when selectedUser or userId changes
  


  
  return (
    <div className="c1content">
      {/* ... (rest of the component code) */}
      <div className="searchcontent">
        <form className="feedback-form" onSubmit={handleSubmit}>
          <input type="hidden" name="side" value="student" />
          <fieldset id="info">
            <legend>CHAT</legend>
            <select
              id="description"
              name="role"
              className="chatdropdown"
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="student">student</option>
              <option value="instructor">Instructor</option>
              <option value="admin">Admin</option>
              <option value="qa">QA</option>
              <option value="pc">PC</option>

            </select>
            <br />
            <br />
            <select name="receiver" onChange={handleSelectedUser}>
              {usersWithRole.map((result) => (
                <option value={result.id} key={result.id}>
                  {result.fname}
                </option>
              ))}
            </select>
            <br />
            <br />
            <textarea
              id="description"
              rows={4}
              cols={50}
              name="message"
              placeholder="Message..."
              value={formData.message}
              onChange={handleFormChange}
            ></textarea>
            <br />
            <br />
            <button type="submit">Submit</button>
            <br />
            <br />
          </fieldset>
        </form>
      </div>
      <br />
      <br />
      <ChatMessages chatRecord={chatRecord} userId={userId} senderName={userId} receiverName={selectedUser}/>
    </div>
  );
}

export default ChatContent;
