import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StudentNavbar from "./StudentNavbar";
import "../../css/global-styles.css";

function Syllabus() {
  const { courseId } = useParams(); // Extract course ID from URL
  const [syllabusUrl, setSyllabusUrl] = useState("");

  useEffect(() => {
    // Fetch the syllabus URL dynamically based on the course ID
    // For example, you might fetch it from your backend like this:
    // axios.get(`https://your-api.com/syllabus/${courseId}`).then(response => setSyllabusUrl(response.data.url));
    // For now, we'll just dynamically generate a URL:
    setSyllabusUrl(`https://axs2459.uta.cloud/syllabus/course101.pdf`);
  }, [courseId]);

  return (
    <div className="page-container">
      <div className="c1main-container">
        <StudentNavbar wrapperClass="c1left-navbar" menuItemClass="c1nav-button" />
        <StudentNavbar wrapperClass="c1right-navbar" menuItemClass="c1nav-button2" isSubMenu />

        <div className="c1content">
          <div className="syllabus">
            <h1 id={`course${courseId}`}>Syllabus for Course {courseId}</h1>
            {/* Embedding PDF for syllabus */}
            {syllabusUrl ? (
              <embed src={syllabusUrl} type="application/pdf" width="100%" height="400px" />
            ) : (
              <p>Loading syllabus...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Syllabus;
