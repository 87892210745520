import React, { useState } from "react";
import "../global-styles.less";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { routes } from "../constants";
import { useUser } from "../UserContext";

const links = [routes.home, routes.login, routes.signup];

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setUserId } = useUser();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://axs2459.uta.cloud/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (data.redirect) {
        // If there is a redirect URL, we simply redirect the user to that URL
        window.location.href = data.redirect;
      } else if (data.valid) {
        // Set the user ID in local storage or context
        setUserId(username);

        // Determine the redirection route based on the user's role
        const redirectionRoute = getRedirectionRoute(data.role);
        navigate(redirectionRoute.to);
      } else {
        // Handle invalid login
        alert(data.message);
        // Optionally update UI to show an error message
      }
    } catch (error) {
      // Handle network errors
      alert("Network error"+ error.message);
    }
  };

  const getRedirectionRoute = (userRole) => {
    switch (userRole) {
      case "admin":
        return routes.admin;
      case "student":
        return routes.student;
      case "instructor":
        return routes.instructor;
      case "qa":
        return routes.qao;
      case "pc":
        return routes.pc;
      default:
        return routes.home;
    }
  };

  return (
    <div className="global-styles-ui">
      <div className="pcrpage_container">
        <div className="head">
          <Header links={links} />
          <hr />
        </div>
        <div className="login-container">
          <form onSubmit={handleLogin}>
            <fieldset id="signinfo" className="filldetails for_phone">
              <br />
              <legend>LOGIN</legend>
              <br />
              <div id="signinfohorizontal">
              <label htmlFor="username">User ID &nbsp;&nbsp;&nbsp;</label>
              <input
                name="username"
                type="text"
                id="username"
                placeholder="Enter your username"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              </div>
              <br />
              <div id="signinfohorizontal">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              </div>
              <br />
              <a>
                <p
                  className="forgot-password"
                  onClick={() => navigate("/forgotpassword")}
                >
                  Forgot password?
                </p>
              </a>
              <br />
            </fieldset>
            <br />
            <button type="submit">Login</button>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Login;
