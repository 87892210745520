import React from "react";
import QANavbar from "./QANavbar";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import axios from "axios";
class QAManageCourse extends React.Component {
  constructor(prop) {
    super(prop);
  }
  state = {
    data: [],
    users: [],
    url: "https://axs2459.uta.cloud/deleteCourse.php?q=",
    showModal: false,
    courseToEdit: {
      course_name: "",
      course_objective: "",
      instructor_name: "",
      program_name: "",
      cid: 0,
    },
  };

  openEditModal = (course) => {
    this.setState({
      showModal: true,
      courseToEdit: {
        cid: course.cid, // Include the cid property
        course_name: course.course_name,
        course_objective: course.course_objective,
        instructor_name: course.instructor_name,
        program_name: course.program_name,
      },
    });
  };
  
  closeEditModal = () => {
    this.setState({
      showModal: false,
      courseToEdit: {
        course_name: "",
        course_objective: "",
        instructor_name: "",
        program_name: "",
      },
    });
  };

  fetchCourses = () => {
    axios.get('https://axs2459.uta.cloud/manageCourse.php') // Update with the correct path to your PHP script
      .then((response) => {
        // Assuming the response contains the array of courses
        this.setState({ data: response.data });
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }
  
  handleEditSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('cid', this.state.courseToEdit.cid.toString());
  
    axios({
      method: 'post',
      url: 'https://axs2459.uta.cloud/editCourse.php', // Make sure this is the correct URL
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' },
    })
    // .then(response => {
    //   // Assuming the server responds with a success status
    //   if (response.data.success) {
    //     // If the update is successful, refresh the list of courses
    //     this.fetchCourses();
    //     // And close the modal
    //     this.setState({ showModal: false, courseToEdit: null });
    //   } else {
    //     // Handle any errors returned by the server
    //     console.error('Update failed:', response.data.message);
    //   }
    // })
    // .catch(error => {
    //   // Handle errors here
    //   console.error('Error submitting form:', error);
    // });
    .then(response => {
      if (response.data.success) {
        this.fetchCourses();
        this.setState({ 
          showModal: false,
          courseToEdit: {
            course_name: "",
            course_objective: "",
            instructor_name: "",
            program_name: "",
            cid: 0,
          }
        });
      } else {
        console.error('Update failed:', response.data.message);
      }
    })
    
  };
  
  componentDidMount() {
    axios.get("https://axs2459.uta.cloud/fetchinstructor.php")
      .then((res) => {
        if (res.data && Array.isArray(res.data.instructors)) {
          this.setState({ users: res.data.instructors });
        } else {
          console.error("Fetch instructors response not as expected:", res.data);
          this.setState({ users: [] });
        }
      })
      .catch((error) => {
        console.error("Error fetching instructors:", error);
        this.setState({ users: [] });
      });
  
    axios.get("https://axs2459.uta.cloud/manageCourse.php")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.setState({ data: res.data });
        } else {
          console.error("Fetch courses response not as expected:", res.data);
          this.setState({ data: [] });
        }
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        this.setState({ data: [] });
      });
  }
  
  render() {
    const { data, showModal, courseToEdit } = this.state;
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: "20px",
    };
    return (
      <div className="amcpage_container">
        <div className="amcmain-container">
        <QANavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
        />
          {/* starting changes for the modal */}
          <Modal
            open={showModal}
            onClose={this.closeEditModal}
            aria-labelledby="edit-course-modal-title"
            aria-describedby="edit-course-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="edit-course-modal-title"
                variant="h6"
                component="h2"
              >
                Edit Course
              </Typography>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={this.handleEditSubmit}
                // action="https://axs2459.uta.cloud/editCourse.php" // Set this to the actual path
                // method="post"
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="course_name"
                  label="Course Name"
                  name="course_name"
                  value={courseToEdit.course_name}
                  onChange={(e) =>
                    this.setState({
                      courseToEdit: {
                        ...courseToEdit,
                        course_name: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="course_objective"
                  label="Course Objective"
                  name="course_objective"
                  value={courseToEdit.course_objective}
                  onChange={(e) =>
                    this.setState({
                      courseToEdit: {
                        ...courseToEdit,
                        course_objective: e.target.value,
                      },
                    })
                  }
                  multiline
                  rows={4}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="instructor_name"
                  label="Instructor Name"
                  name="instructor_name"
                  value={courseToEdit.instructor_name}
                  onChange={(e) =>
                    this.setState({
                      courseToEdit: {
                        ...courseToEdit,
                        instructor_name: e.target.value,
                      },
                    })
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="program_name"
                  label="Program Name"
                  name="program_name"
                  value={courseToEdit.program_name}
                  onChange={(e) =>
                    this.setState({
                      courseToEdit: {
                        ...courseToEdit,
                        program_name: e.target.value,
                      },
                    })
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Save Changes
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, mb: 2 }}
                  onClick={this.closeEditModal}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          {/* ending changes for the modal  */}

          <div className="amccontent">
            <div className="form">
              <form
                id="admin_form"
                action="https://axs2459.uta.cloud/addcourse.php"
                method="POST"
              >
                <fieldset id="admc">
                  <legend>Add a New Course</legend>

                  <label htmlFor="courseName">Course Name</label>
                  <input
                    type="text"
                    id="courseName"
                    name="courseName"
                    required
                  />

                  <label htmlFor="courseObjective">Course Objective</label>
                  <textarea
                    id="description"
                    name="courseObjective"
                    rows={8}
                    cols={40}
                  ></textarea>

                  <label htmlFor="instructorName">Instructor Name</label>
                  <select id="instructorName" name="instructorName">
                    { Array.isArray( this.state.users.map) && this.state.users.map((instructor) => (
                      
                      <option key={instructor.id} value={instructor.name}>{instructor.name}</option>
                      
                    ))}
                  </select>

                  <label htmlFor="programName">Program Name</label>
                  <select id="programName" name="programName">
                    <option value="mscComputerScience"></option>
                    <option value="mscComputerScience">
                      MSC in Computer Science
                    </option>
                    <option value="mscDataScience">MSC in Data Science</option>
                    <option value="mscAI">
                      MSC in Artificial Intelligence
                    </option>
                  </select>
                  <br />
                </fieldset>
                <br />
                <button type="submit">Add Course</button>
              </form>
            </div>
            <div className="amctable">
              <table className="amcgrades-table">
                <thead>
                  <tr className="amcgrades-table-row">
                    <th className="amcgrades-table-header">Course Name</th>
                    <th className="amcgrades-table-header">Course Objective</th>
                    <th className="amcgrades-table-header">Instructor Name</th>
                    <th className="amcgrades-table-header">Program Name</th>
                    <th className="amcgrades-table-header">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((result) => {
                    return (
                      <tr className="amcgrades-table-row">
                        <td>{result.course_name}</td>
                        <td>{result.course_objective}</td>
                        <td>{result.instructor_name}</td>
                        <td>{result.program_name}</td>

                        <td>
                          <button
                            type="submit"
                            onClick={() => this.openEditModal(result)}
                          >
                            Edit
                          </button>
                          <a href={this.state.url + result.cid}>
                            <button
                              style={{ backgroundColor: "red" }}
                              type="submit"
                            >
                              Delete
                            </button>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <br />
            <br />
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default QAManageCourse;