import React, { useState, useEffect } from "react";
import InstructorNavbar from "./InstructorNavbar";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, MenuItem, TextField } from "@mui/material";
import { useUser } from "../../UserContext";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

interface Course {
  cid: number;
  course_name: string;
  passPercentage: number;
}
interface Module {
  module_id: number;
  module_title: string;
  total_marks: string;
}

interface Exam {
  exam_id: number;
  mean: number;
  low: number;
  high: number;
  total_marks: number;
  date: string;
  students: number;
}
export default function InstructorModules() {
  const [open, setOpen] = useState(false);
  const { userId, setUserId } = useUser();
  const [courses, setCourses] = useState<Course[]>([]);
  const [instructorName, setInstructorName] = useState("");
  const [exams, setExams] = useState<Exam[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [selectedExamData, setSelectedExamData] = useState<any[]>([]);

  const [examCourseId, setExamCourseId] = useState<number | "">("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [examTitle, setExamTitle] = useState("");

  const [totalMarks, setTotalMarks] = useState("");

  const [openExamModal, setOpenExamModal] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);

  const [moduleTitle, setModuleTitle] = useState("");
  const [moduleTotalMarks, setModuleTotalMarks] = useState("");
  const handleCloseExamModal = () => {
    
    // Clear the exam fields
    setExamTitle("");
    setTotalMarks("");
    setOpenExamModal(false);
  };

  const [students, setStudents] = useState([]); // Add a suitable interface for student data

  // // Function to handle the submit action
  // const handleAddExam = () => {
  //   // You would submit these values to your backend here
  //   console.log({
  //     examTitle,
  //     totalMarks,

  //   });

  //   handleCloseExamModal(); // Close the modal and clear the fields
  // };
  useEffect(() => {
    if (userId) {
      axios
        .post("https://axs2459.uta.cloud/profile.php", { user_id: userId })
        .then((response) => {
          // Check if there is an error in the response
          if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            // Assuming the response data has the user's first and last name
            const { fname, lname } = response.data;
            setInstructorName(`${fname} ${lname}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching instructor information:", error);
        });
    }
  }, [userId]); // This effect runs when the userId changes.
  //const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Assuming you fetch the instructor's ID and set it in the userId state
    if (userId) {
      axios
        .post("https://axs2459.uta.cloud/coursesinstructor.php", {
          instructorName: instructorName,
        })
        .then((response) => {
          if (Array.isArray(response.data)) {
            setCourses(response.data);
          } else if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            console.error("Unexpected response:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching courses:", error);
        });
    }
  }, [userId, instructorName]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };
  
  const handleExamClick = (exam: Exam) => {
    // Prepare the data for the bar chart
    const chartData = [
      { name: "Mean", value: exam.mean },
      { name: "Low", value: exam.low },
      { name: "High", value: exam.high },
    ];
    setSelectedExamData(chartData);
  };
  const handleCourseClick = (cid: number) => {
    console.log(`Selected Course ID: ${cid}`);
    setSelectedCourseId(cid);

    // Make sure headers are set to application/json
    axios
      .post(
        "https://axs2459.uta.cloud/pcexams.php",
        JSON.stringify({ course_id: cid }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response); // Print the whole response for debugging
        console.log("Response data:", response.data); // Print the response data for debugging

        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          setExams(response.data);
        } else {
          console.error("Data is not an array", response.data);
          // If the response is not an array, handle it as needed
          // For example, check for an error field in the response
          if (response.data.error) {
            console.error("Error fetching exams:", response.data.error);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching exams:", error);
      });

    axios
      .post(
        "https://axs2459.uta.cloud/getmodules.php",
        JSON.stringify({ course_id: cid }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response); // For debugging

        // Check if the response data is an array (assuming modules data is an array)
        if (Array.isArray(response.data)) {
          setModules(response.data); // Update the state with the fetched modules data
        } else {
          // Handle cases where data is not as expected
          console.error("Unexpected data format:", response.data);
          if (response.data.error) {
            console.error("Error fetching modules:", response.data.error);
          }
        }
      })
      .catch((error) => {
        // Handle errors in API call
        console.error("Error fetching modules:", error);
      });
    axios
      .post("https://axs2459.uta.cloud/fetchstudents.php", { course_id: cid })
      .then((response) => {
        if (Array.isArray(response.data)) {
          // Now make another request to get detailed information of these students
          axios
            .post("https://axs2459.uta.cloud/getstudnetslist.php", {
              student_ids: response.data.map((s) => s.student_id),
            })
            .then((detailedResponse) => {
              // Update the state with detailed information
              setStudents(detailedResponse.data);
            })
            .catch((error) => {
              console.error(
                "Error fetching detailed student information:",
                error
              );
            });
        } else {
          console.error("Data is not an array", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching student IDs:", error);
      });
  };
  const handleModalOpen = () => {
    setOpen(true);
  };
  const [selectedModule, setSelectedModule] = useState(null);
  const [isModuleModalOpen, setIsModuleModalOpen] = useState(false);

  const handleEditModule = (event: React.FormEvent) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("module_id", selectedModule.module_id.toString());
    formData.append("module_title", moduleTitle);
    formData.append("total_marks", totalMarks);

    fetch("https://axs2459.uta.cloud/editmodulesinfo.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsModuleModalOpen(false);

        if (data.error) {
          console.error("Error editing module:", data.error);
        } else {
          console.log("Module edited successfully:", data);
          // You can perform further actions here such as refreshing the modules list
          setIsModuleModalOpen(false);
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error("Error editing module:", error);
      });
  };

  const handleDeleteModule = async (moduleID) => {
    // Make an API call to delete the module
    const formData = {
      module_id: moduleID,
    };
    try {
      const response = await axios.post(
        "https://axs2459.uta.cloud/deletemodulesinfo.php",
        formData
      );
      // Handle response
      console.log(response.data);
      setIsModuleModalOpen(false);
      window.location.reload()
    } catch (error) {
      console.error("Error deleting module:", error);
    }
  };

  // ... existing return statement and other JSX ...

  // // Function to close the modal and clear form fields
  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  //   // Clear the form fields
  //   setExamTitle('');

  //   setTotalMarks('');

  // };
  console.log("Instructor ID:", userId);
  // Function to handle form submission
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    handleCloseExamModal();
    // Prepare the data to be sent to the PHP backend
    const formData = {
      instructor_id: userId,
      course_id: examCourseId,
      exam_title: examTitle,

      total_marks: totalMarks,
    };
    

    // Perform the POST request to your PHP endpoint
    axios
      .post("https://axs2459.uta.cloud/addmoduleinfo.php", formData)
      .then((response) => {
        // Handle the response from the server here
        console.log("Response from the server:", response.data);
        // handleCloseExamModal();
        // You might want to check if the insert was successful and then do something
        if (response.data.success) {
          handleClose();
          window.location.reload()
        }
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error submitting form:", error);
      });

    // handleCloseExamModal(); // Close the modal after form submission
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleModuleClick = (module) => {
    setSelectedModule(module);
    setModuleTitle(module.module_title);
    setModuleTotalMarks(module.total_marks);
    setIsModuleModalOpen(true);
  };

  return (
    <div className="page-container">
      {/* MAIN CONTENT = NO HEADER AND FOOTER */}
      <div className="main-container">
        {/* LEFT NAVBAR */}
        <InstructorNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
        />

        
        <div>
        <button
  type="submit"
  className="mark button primary-button"
  style={{
    backgroundColor: 'green', // Add green background color
    // color: 'white', // Set text color to white
    display: 'block', // Make it a block element
    margin: '0 auto', // Center horizontally
    marginTop: '20px', // Add some top margin
  }}
  onClick={handleModalOpen}
>
  Add Module
</button>
          <>
          <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography variant="h6">Add New Module</Typography>
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ mt: 1 }}
    >
      {/* Input fields for the form */}
      <TextField
        margin="normal"
        fullWidth
        select
        label="Course Name"
        value={examCourseId}
        onChange={(e) => setExamCourseId(Number(e.target.value))}
        required
      >
        <MenuItem value="">Select a course</MenuItem>
        {courses.map((course) => (
          <MenuItem key={course.cid} value={course.cid}>
            {course.course_name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        margin="normal"
        required
        fullWidth
        label="Module Title"
        value={examTitle}
        onChange={(e) => setExamTitle(e.target.value)}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        label="Module Link"
        value={totalMarks}
        onChange={(e) => setTotalMarks(e.target.value)}
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>
      
    </Box>
  </Box>
</Modal>


          </>
          

          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <button type="submit" onClick={() => setIsModalOpen(false)}>
                  Close
                </button>
              </div>
            </div>
          )}

          <div className="content">
            {courses.map((course, index) => (
              <div
                className="course-box"
                key={course.cid}
                onClick={() => handleCourseClick(course.cid)}
              >
                <a href="#">
                  Course {index + 1}
                  <br />
                  <br />
                  <br />
                  {course.course_name}
                </a>
              </div>
            ))}

            {/* displaying students list */}

            {selectedCourseId && modules.length > 0 && (
              <table
                style={{
                  width: "80%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#4CAF50", color: "white" }}>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Module ID
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Module Title
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Module Link
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {modules.map((module, index) => (
                    <tr
                      key={module.module_id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            handleModuleClick(module);
                          }}
                        >
                          {module.module_id}
                        </a>
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {module.module_title}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        <a
                          href={module.total_marks}
                          style={{ color: "#2a84d8", textDecoration: "none" }}
                        >
                          Link
                        </a>
                      </td>
                      <td>
                        <button
                          type="submit"
                          style={{ backgroundColor: "red", color: "white" }}
                          onClick={() => {
                            setSelectedModule(module); // Set the selected module
                            handleDeleteModule(module.module_id); // Then call the delete function
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {isModuleModalOpen && selectedModule && (
              <Modal
                open={isModuleModalOpen}
                onClose={() => setIsModuleModalOpen(false)}
                aria-labelledby="module-modal-title"
                aria-describedby="module-modal-description"
              >
                <Box sx={style}>
                  <h2>Edit Module Information</h2>
                  <form onSubmit={handleEditModule}>
                    <div>
                      <label>Module ID: {selectedModule.module_id}</label>
                    </div>
                    <div>
                      <label>
                        Module Title:
                        <input
                          type="text"
                          value={moduleTitle}
                          onChange={(e) => setModuleTitle(e.target.value)}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        Total Marks:
                        <input
                          type="text"
                          value={moduleTotalMarks}
                          onChange={(e) => setModuleTotalMarks(e.target.value)}
                        />
                      </label>
                    </div>
                    <div>
                      <button type="submit" onClick={handleEditModule}>
                        Save Changes
                      </button>

                      <button
                        type="button"
                        onClick={() => setIsModuleModalOpen(false)}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </Box>
              </Modal>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}
