import React, { useEffect, useState } from "react";
import axios from 'axios';
import PCNavbar from "./PCNavbar";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

interface Course {
  id: number;
  name: string;
  passPercentage: number;
}

interface Exam {
  exam_id: number;
  mean: number;
  low: number;
  high: number;
  total_marks: number;
  date: string;
  students: number;
}
function PCReports() {

  const [courses, setCourses] = useState<Course[]>([]);
  const [exams, setExams] = useState<Exam[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [selectedExamData, setSelectedExamData] = useState<any[]>([]);
  useEffect(() => {
    const apiEndpoint = 'https://axs2459.uta.cloud/pccourses.php';

    axios.get(apiEndpoint)
      .then(response => {
        // Check if the response has a success flag and data is an array
        if (Array.isArray(response.data)) {
          setCourses(response.data);
          console.log('Data fetched successfully:', response.data);
        } else {
          console.error("Unexpected response:", response.data);
        }
      })
      .catch(error => {
        console.error("Error fetching courses:", error);
      });
  }, []);
  const handleCourseClick = (courseId: number) => {
    setSelectedCourseId(courseId);

    // Make sure headers are set to application/json
    axios.post('https://axs2459.uta.cloud/pcexams.php', JSON.stringify({ course_id: courseId }), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log('Response:', response); // Print the whole response for debugging
        console.log('Response data:', response.data); // Print the response data for debugging

        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          setExams(response.data);
        } else {
          console.error('Data is not an array', response.data);
          // If the response is not an array, handle it as needed
          // For example, check for an error field in the response
          if (response.data.error) {
            console.error('Error fetching exams:', response.data.error);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching exams:', error);
      });
  };
  const handleExamClick = (exam: Exam) => {
    // Prepare the data for the bar chart
    const chartData = [
      { name: 'Mean', value: exam.mean },
      { name: 'Low', value: exam.low },
      { name: 'High', value: exam.high }
    ];
    setSelectedExamData(chartData);
  };


  return (
    <div className="pcrpage_container">
      <div className="pcrmain-container">
        <PCNavbar />
        <div className="right-content">
          <div className="PC_container">
            {courses.map(course => (
              <div className="card" key={course.id} onClick={() => handleCourseClick(course.id)}>
                <h2>{course.name}</h2>
                {/* <p>Pass Percentage: {course.passPercentage}%</p> */}
              </div>
            ))}
            
            {selectedCourseId && exams.length > 0 && (
              <>
              <br/><br/>
            {/* <h3>Exams for {courses.find(c => c.id === selectedCourseId)?.name}:</h3> */}
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
              <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
                  <tr>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Exam ID</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Students</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Mean</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Low</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>High</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Marks</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
    </tr>
                  </thead>
                  <tbody>
                    {exams.map((exam, index) => (
                      <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                        <td  style={{ border: '1px solid #ddd', padding: '8px' }}>
                          <a href="#!" onClick={(e) => { e.preventDefault(); handleExamClick(exam); }}>
                            {exam.exam_id}
                          </a>
                        </td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.students}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.mean.toFixed(2)}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.low}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.high}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.total_marks}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{exam.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br/>
                <br/>
                {selectedExamData.length > 0 && (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={selectedExamData} key={Date.now()}>
      <CartesianGrid strokeDasharray="0.1 0.1" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip
        cursor={{ fill: 'transparent' }}
        contentStyle={{ backgroundColor: 'rgba(255,255,255,0.85)', border: 'none' }}
        formatter={(value, name) => [value, name]}
      />
      <Legend />
      <Bar
        dataKey="value"
        fill="#2a84d8"
        barSize={30}
        isAnimationActive={true}
        animationDuration={1200}
        // Add animations for the bar
        onAnimationStart={() => console.log('Animation started')}
        onAnimationEnd={() => console.log('Animation ended')}
      />
    </BarChart>
  </ResponsiveContainer>
)}

              </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default PCReports;
