import React, { useState, useEffect } from "react";
import AdminNavbar from "./AdminNavbar";
import { adminRoutes } from "../../constants";
import CommonNavbar from "../Shared/CommonNavbar";

const ManagePermissions = () => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    fetch("https://axs2459.uta.cloud/verifyusersrole.php")
      .then((response) => response.json())
      .then((data) => setUserList(data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  const handleVerifyUser = (userId) => {
    // Find the full user details based on userId
    const userToVerify = userList.find(user => user.id === userId);
    if (!userToVerify) {
      console.error("User not found");
      return;
    }

    // Set up the options for the fetch request
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userToVerify)
    };

    // Make the POST request to the server
    fetch('https://axs2459.uta.cloud/verifiedusers.php', requestOptions)
      .then(response => response.json())
      .then(data => {
        // Handle response data
        console.log(data);
        const updatedUserList = userList.map(user => {
            if (user.id === userId) {
              return { ...user, isVerified: true }; // Assuming you have an 'isVerified' property in your user data
            }
            return user;
          });
          fetch('https://axs2459.uta.cloud/deleteselectedverifieduser.php', requestOptions)
          .then(response => response.json())
          .then(data => {
              if (data.success) {
                  // Remove the user from the userList in state
                  const updatedUserList = userList.filter(user => user.id !== userId);
                  setUserList(updatedUserList);
              }
          })
          .catch(error => console.error('Error removing user:', error));
          setUserList(updatedUserList);
      })

      .catch(error => {
        console.error('Error verifying user:', error);
      });
      fetch('https://axs2459.uta.cloud/setverifieduser.php', requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log("Second API response:", data);
          fetch('https://axs2459.uta.cloud/deleteselectedverifieduser.php', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            // Remove the user from the userList in state
                            const updatedUserList = userList.filter(user => user.id !== userId);
                            setUserList(updatedUserList);
                        }
                    })
                    .catch(error => console.error('Error removing user:', error));
        })
        .catch(error => {
          console.error('Error in second API call:', error);
        });
  };
  
   

  
  return (
    <div className="pcrpage_container">
      <div className="main-container">
        <CommonNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
          routes={adminRoutes}
        />
        <div className="right-content">
          <h2>User List</h2>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Last Name</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Phone</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Email</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Role</th>
                <th  style={{ border: '1px solid #ddd', padding: '8px' }}>Verify User</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.id}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.name}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.lname}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.phone}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.email}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.role}</td>
                  <td>
                    <button disabled={user.isVerified} onClick={() => handleVerifyUser(user.id)}>VERIFY</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManagePermissions;