import React,{ useState, useEffect } from "react";
import axios from "axios";
import InstructorNavbar from "./InstructorNavbar";
import "./StudentsList.module.css";
import {instructorRoutes } from "../../constants";
import { useUser } from "../../UserContext";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
function InstructorFeedbackList() {
  const [isLoading, setIsLoading] = useState(false);

  const { userId, setUserId } = useUser();
  const [feedbackList, setFeedbackList] = useState([]);
  const [error, setError] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedStudentDetails, setSelectedStudentDetails] = useState({
    email: '',
    name: '',
  });
  const Spinner = () => (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div style={{
        border: '5px solid #f3f3f3', /* Light grey */
        borderTop: '5px solid #3498db', /* Blue */
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        animation: 'spin 2s linear infinite',
      }} />
    </div>
  );
  
  useEffect(() => {
    // Fetch the student data when the component mounts
    setIsLoading(true);
    axios.get('https://axs2459.uta.cloud/getstudentsforfeedback.php')
      .then(response => {
        if (response.data) {
          setStudents(response.data);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the student data: ", error);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    // Find the student details based on the selected ID
    const studentDetails = students.find(student => student.id === selectedStudentId);
    // Update the selected student details state
    setSelectedStudentDetails({
      email: studentDetails?.email || '',
      name: studentDetails?.name || '',
    });
  }, [selectedStudentId, students]);
  useEffect(() => {
    // Fetch feedback when the component mounts
    axios.get("https://axs2459.uta.cloud/fetchfeedback.php")
      .then(response => {
        setFeedbackList(response.data);
      })
      .catch(error => {
        console.error("Error fetching feedback: ", error);
        setError("Error fetching feedback");
      });
  }, []);

 
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState({
    studentId: "",
    name:"",
    email:"",
    subject: "",
    feedbackText: "",
  });
  const feedbackPayload = {
    studentId: selectedStudentId,
    name: selectedStudentDetails.name, // Make sure this is assigned
    email: selectedStudentDetails.email, // Make sure this is assigned
    subject: feedback.subject,
    feedbackText: feedback.feedbackText,
  };
  
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://axs2459.uta.cloud/addFeedback.php", feedbackPayload);
      console.log(response.data); 
      if (response.data.success) {
        // Feedback added successfully

        handleCloseModal();
        // You might want to refresh the feedback list here
        window.location.reload()
      } else {
        // Handle error here
        console.error("Failed to add feedback", response.data.message);
      }
    } catch (error) {
      console.error("Error submitting feedback", error);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <div className="page-container">
      <div className="main-container">
        <InstructorNavbar wrapperClass="left-navbar"
          menuItemClass="nav-button"/>
        <div>
          <button style={{
    backgroundColor: 'green', // Add green background color
    // color: 'white', // Set text color to white
    display: 'block', // Make it a block element
    margin: '0 auto', // Center horizontally
    marginTop: '20px', // Add some top margin
  }} type="submit" className="mark button primary-button" onClick={handleOpenModal}>
            Add Feedback
          </button>
          
          <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="add-feedback-modal-title"
          aria-describedby="add-feedback-modal-description"
        >
          <Box sx={style}>
            <Typography id="add-feedback-modal-title" variant="h6" component="h2">
              Add Feedback
            </Typography>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmitFeedback}>
              
               <select
        value={selectedStudentId}
        onChange={(e) => setSelectedStudentId(e.target.value)}
      >
        
        <option value="">Select a Student</option>
        {students.map((student) => (
          <option key={student.id} value={student.id}>
            {student.id}
          </option>
        ))}
      </select>
      
              <TextField
                margin="normal"
                required
                fullWidth
                id="subject"
                label="Subject"
                name="subject"
                value={feedback.subject}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="feedbackText"
                label="Feedback"
                name="feedbackText"
                value={feedback.feedbackText}
                onChange={handleChange}
                multiline
                rows={4}
              />
              <TextField
          margin="normal"
          fullWidth
          id="name"
          label="Student Name"
          name="name"
          value={selectedStudentDetails.name}
          InputProps={{
            readOnly: true,
          }}
          variant="filled"
        />
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label="Student Email"
          name="email"
          value={selectedStudentDetails.email}
          InputProps={{
            readOnly: true,
          }}
          variant="filled"
        />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Submit Feedback
              </Button>
              <Button fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={handleCloseModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
          <div className="feedback-container">
          {isLoading ? (
    <Spinner />
  ) : (
            <table style={{ marginLeft:"70px",width: "100%", borderCollapse: "collapse" }}>
            <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
                <tr>
                  <th style={{ border: "1px solid black", padding: "8px" }}>S.No.</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>ID</th> {/* Updated */}
                  <th style={{ border: "1px solid black", padding: "8px" }}>Name</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Email</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Subject</th>
                  <th style={{ border: "1px solid black", padding: "8px" }}>Feedback</th>
                </tr>
              </thead>
              <tbody >
                {feedbackList.length > 0 ? (
                  feedbackList.map((feedback, index) => (
                    <tr key={index}style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
          
                      <td style={{ border: "1px solid black", padding: "8px" }}>{index + 1}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{feedback.id}</td> {/* Updated */}
                      <td style={{ border: "1px solid black", padding: "8px" }}>{feedback.name}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                     <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${feedback.email}`}>
                      {feedback.email}
                      </a></td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{feedback.subject}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{feedback.feedback}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td >{error ? error : "No feedback available."}</td> {/* Updated the colSpan */}
                  </tr>
                )}
              </tbody>
              </table>
               )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorFeedbackList;