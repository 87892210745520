import React, { useEffect, useState } from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";
import { useUser } from "../../UserContext";
import axios from 'axios';


function StudentExams() {

  const { userId, setUserId } = useUser();
  // setting global user id to state 
  const [studentId, setStudentId] = useState(userId);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [error, setError] = useState('');
  const [exams, setExams] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [modules, setModules] = useState([]);
  useEffect(() => {
    if (!userId) {
      setError('User student data not logged in.');
      return;
    }

    // Make a POST request to fetch enrolled courses
    axios.post(`https://axs2459.uta.cloud/get_courses.php`, { student_id: userId })
      .then(response => {
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setEnrolledCourses(response.data);
        }
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, [userId]);

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    setSelectedCourseId(courseId);
    setModules([]); // Clear modules when changing course
    if (courseId) {
      // Make a POST request to fetch modules for the selected course
      axios.post(`https://axs2459.uta.cloud/get_course_modules.php`, { course_id: courseId })
        .then(response => {
          // handle response
          if (response.data.error) {
            setError(response.data.error);
          } else {
            setModules(response.data.modules || []);
          }
        })
        .catch(error => {
          // handle error
          console.error("Error fetching modules info: ", error);
        });
    }
  };

 
  
  return (
    <div className="sepage-container">
      <div className="semain-container">

        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
       
        <div className="secontent">
          
        <div className="content">
          {error ? (
            <p>{error}</p>
          ) : (
            <select id="info" style={{ width: '200px', height:'30px' }} onChange={handleCourseChange} value={selectedCourseId}>
              <option value="">Select a course</option>
              {enrolledCourses.map(course => (
                <option key={course.ecid} value={course.ecid}>
                  {course.course_name}
                </option>
              ))}
            </select>
          )}
        </div>
          
        <div id="modules" style={{ display: "flex", flexWrap: "wrap" }}>
            {modules.length > 0 ? modules.map((module, index) => (
              <div className="module-detail" key={index}>
                <h3>{module.module_name}</h3>
                <ul>
                  <li>Module ID: {module.module_id}</li>
                  <li>Course ID: {module.course_id}</li>
                  <li>Module Title: {module.module_title}</li>
                  <li>Link: {module.total_marks}</li>
                  {/* Display other module information here */}
                </ul>
              </div>
            )) : <p>No modules available for this course.</p>}
          </div>

        </div>
      </div>
    </div>
  );
}

export default StudentExams;
