import React, { useState, FormEvent, useEffect } from "react";
import PCNavbar from "./PCNavbar";
import axios from 'axios';
function PCGoals() {


  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  const [goals, setGoals] = useState([]);
  const [programGoals, setProgramGoals] = useState([]);
  const [programGoalsmscs, setProgramGoalsmscs] = useState([]);
  const [editingGoal, setEditingGoal] = useState(null);
  const handleGoalClick = (goal) => {
    setEditingGoal(goal);
    setProgramInput(goal.pcgoals || goal.pcmscsgoals);
    setShowProgramForm(true);
  };
  const handleProgramSubmit = async (e) => {
    e.preventDefault();
    if (editingGoal) {
      try {
        // API call to update the goal
        const response = await axios.post('https://axs2459.uta.cloud/updatepcgoals.php', {
          id: editingGoal.id,
          newGoal: programInput
        });

        if (response.data.success) {
          // Update the goal in the local state
          const updatedGoals = programGoals.map(goal => {
            if (goal.id === editingGoal.id) {
              return { ...goal, pcgoals: programInput }; // Or pcmscsgoals based on your data structure
            }
            return goal;
          });
          setProgramGoals(updatedGoals);
        } else {
          console.error('Failed to update the goal');
        }
      } catch (error) {
        console.error('Error updating the goal:', error);
      }
    } else {
      try {
        const response = await axios.post('https://axs2459.uta.cloud/insertpcgoals.php', {
          goal: programInput
        });
  
        if (response.data.success) {
          // Add the new goal to the local state
          // You might want to fetch the goals again to get the new ID
          // Or if the API returns the new goal, use it directly
          setProgramGoals([...programGoals, { id: response.data.newId, pcgoals: programInput }]);
        } else {
          console.error('Failed to add the goal');
        }
      } catch (error) {
        console.error('Error adding the goal:', error);
      }
    //  setProgramGoals([...programGoals, { id: 'newId', pcgoals: programInput }]);
    }
    setProgramInput('');
    setShowProgramForm(false);
    setEditingGoal(null); // Reset editing goal
  };

  useEffect(() => {
    const fetchProgramGoals = async () => {
      try {
        const response = await axios.get('https://axs2459.uta.cloud/programgoals.php');
        if (response.data && Array.isArray(response.data)) {
          setProgramGoals(response.data);
        } else {
          // Handle unexpected structure
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching program goals:', error);
      }
    };
    const fetchProgramGoalsMSCS = async () => {
      try {
        const response = await axios.get('https://axs2459.uta.cloud/programgoalsmscs.php');
        if (response.data && Array.isArray(response.data)) {
          setProgramGoalsmscs(response.data);
        } else {
          // Handle unexpected structure
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching program goals:', error);
      }
    };
    // Similar adjustment for fetchProgramGoalsMSCS

    fetchProgramGoals();
    fetchProgramGoalsMSCS();
  }, []);

  
  const [programInput, setProgramInput] = useState('');
  const [showProgramForm, setShowProgramForm] = useState(false);
  const [isChecklistVisible, setIsChecklistVisible] = useState(false);
  const [profile, setProfile] = useState({
    firstname: 'John Snow',
    lastname: 'Stark',
    email: 'jsk@mavs.uta.edu',
    password: 'kiginthenorth@7',
    phone: '6823741633'
  });
  const [isEditProfileModalVisible, setIsEditProfileModalVisible] = useState(false);
  const handleProgramInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProgramInput(e.target.value);
  };

  const handleAddProgramGoal = () => {
    if (programInput.trim() !== '') {
      setProgramGoals([...programGoals, programInput]);
      setProgramInput('');
      setShowProgramForm(false);
    }
  };
  const [selectedProgramGoals, setSelectedProgramGoals] = useState<number[]>([]);

  const toggleProgramGoalSelection = (index: number) => {
    if (selectedProgramGoals.includes(index)) {
      setSelectedProgramGoals((prev) => prev.filter((i) => i !== index));
    } else {
      setSelectedProgramGoals((prev) => [...prev, index]);
    }
  };

  const handleDeleteProgramGoals = () => {
    setProgramGoals((prev) => prev.filter((_, index) => !selectedProgramGoals.includes(index)));
    setSelectedProgramGoals([]); // Clear selected goals after deletion
  };
  const [input, setInput] = useState('');
  const [showForm, setShowForm] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input) {
      setGoals([...goals, input]);
      setInput('');
      setShowForm(false);
    }
  };
  const handleAddGoal = () => {
    if (input.trim() !== '') {
      setGoals([...goals, input]);
      setInput('');
      setShowForm(false);
    }
  };
  const [selectedGoals, setSelectedGoals] = useState<number[]>([]);

  const toggleGoalSelection = (index: number) => {
    if (selectedGoals.includes(index)) {
      setSelectedGoals((prev) => prev.filter((i) => i !== index));
    } else {
      setSelectedGoals((prev) => [...prev, index]);
    }
  };
  const handleDeleteSelectedGoal = async (goalId) => {
    try {
      // Make an API call to deletepcgoals.php with the selected goal ID
      const response = await axios.post('https://axs2459.uta.cloud/deletepcgoals.php', { id: goalId });
  
      if (response.data.success) {
        // Remove the goal from the local state
        const updatedGoals = programGoals.filter(goal => goal.id !== goalId);
        setProgramGoals(updatedGoals);
      } else {
        // Handle the case where deletion was unsuccessful
        console.error('Failed to delete the goal:', response.data.message);
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('Error deleting the goal:', error);
    }
  };
  
  const handleProfileUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      firstname: { value: string };
      lastname: { value: string };
      mail: { value: string };
      pwd: { value: string };
      phone: { value: string };
    };

    const updatedProfile = {
      firstname: target.firstname.value,
      lastname: target.lastname.value,
      email: target.mail.value,
      password: target.pwd.value,
      phone: target.phone.value
    };

    setProfile(updatedProfile);
    setIsEditProfileModalVisible(false);
    setIsProfileModalVisible(false);
  };




  return (
    <div className="pcrpage_container">

      <div className="pcgoals-main-container">



        <PCNavbar />

        <div className="pcgoals_container">

          <div className="program-goals">
          <h2 style={{ color: '#123abc' }}>Program Goals for MS in Computer Science</h2>
            <button type="submit" className="add-button" onClick={() => setShowProgramForm(!showProgramForm)}>
              {showProgramForm ? "Cancel" : "Add"}
            </button>

            <ol>
              {programGoals.map((goal) => (
                <li key={goal.id} className="goal-item" onClick={() => handleGoalClick(goal)}>
                  <div className="goal-text" onClick={() => handleGoalClick(goal)}>
        {goal.pcgoals}
      </div>
                  <button type="submit" style={{ display:"flex", flexFlow:"row", flexWrap:"nowrap", alignItems:"center",height:"30px",backgroundColor: "red"}} onClick={() => handleDeleteSelectedGoal(goal.id)}>Delete</button>
                </li>
              ))}
            </ol>
           

           


            {showProgramForm && (
              <div>
                <form onSubmit={handleProgramSubmit}>
                  <textarea
                    style={{ width: '100%', height: '300px' }}
                    value={programInput}
                    onChange={handleProgramInputChange}
                    rows={4}
                    placeholder="Enter your program goal"
                  />
                </form>
                <button type="submit" onClick={handleProgramSubmit}>Submit</button>
              </div>
            )}
           



          </div>

          
          <div className="institution-goals">
            <h2 style={{ color: '#123abc' }}>Goals of the Institution</h2>
            <p>
              Our objective is to provide students with the vocabulary,
              theoretical knowledge, and technical know-how necessary to
              comprehend and take part in current multidisciplinary research,
              particularly that which focuses on issues related to language,
              information, and intelligence—both human and artificial.
            </p>
          </div>
          <div className="department-vision">
            <h2 style={{ color: '#123abc' }}>Vision of CS Department</h2>
            <p>
              The vision of the Computer Science department is to be a leader in
              cutting-edge research, innovation, and education in the field of
              computer science. We aim to prepare students to excel in a rapidly
              evolving technological landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PCGoals;
