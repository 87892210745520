
import React from 'react';

function ChatMessages({ chatRecord, userId, senderName, receiverName }) {
  const chatStyles = `
    .chat-container {
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f5f5f5;
      max-width: 400px;
      margin: 0 auto;
      padding: 10px;
      box-shadow: 0px 0px 5px #ccc;
    }

    .old-chats-header {
      background-color: #333;
      color: white;
      text-align: center;
      padding: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .chat-messages {
      padding: 10px;
      overflow-y: auto;
      max-height: 300px;
    }

    .chat-message {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      max-width: 70%;
    }

    .chat-message-header{
      display:flex;
      justify-content:space-between;
      align-items:baseline;
    }

    .sent {
      background-color: #f5f5f5;
      color: black;
      align-self: flex-end;
    }

    .received {
      background-color: #f5f5f5;
      color: #333;
      align-self: flex-start;
    }

    .message-content {
      word-wrap: break-word;
      text-align:left;
    }

    .message-time {
      font-size: 12px;
      text-align: right;
      color: #777;
    }
  `;

  return (
    <div>
      <style>{chatStyles}</style>
      <div className="chat-container">
        <div className="old-chats-header">
          <b>
            <h3>Messages</h3>
          </b>
          <b>
            <p>X</p>
          </b>
        </div>
        <div className="chat-messages">
          {chatRecord.map((message, index) => (
            <div
              key={index}
              className={`chat-message ${message.sender_id === userId ? 'sent' : 'received'}`}
            >
              <div className="chat-message-header">
              {message.sender_id === userId ? (
                <div className="message-sender">You</div>
              ) : (
                <div className="message-sender">{senderName}</div>
              )}
              <div className="message-time">
                {new Date(message.time).toLocaleString()}
              </div>
              </div>
              <div className="message-content">{message.message}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ChatMessages;