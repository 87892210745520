import React, { useState, useEffect } from "react";
import AdminNavbar from "./AdminNavbar";
import axios from 'axios';
import { adminRoutes } from "../../constants";
import CommonNavbar from "../Shared/CommonNavbar";
import { Link } from 'react-router-dom';


function ManageUserActivities () {
  const [selectedRole, setSelectedRole] = useState('student');
  const [users, setUsers] = useState([]);
  const [transform, setTransform] = useState('translateY(20px)');
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    // Start the animation shortly after the component mounts
    const timeoutId = setTimeout(() => {
      setTransform('translateY(0)');
      setOpacity(1);
    }, 100); // Start after 100ms delay

    // Clean up the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    // Fetch user data when the component mounts or selectedRole changes
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append('role', selectedRole);

        const response = await axios.post('https://axs2459.uta.cloud/fetchUserspc.php', formData);
        console.log('Received data:', response.data);

        // Check if the response.data is an array before setting it
        if (Array.isArray(response.data)) {
          setUsers(response.data);
        } else {
          // If it's not an array, log an error or handle as needed
          console.error('Data received is not an array:', response.data);
          setUsers([]); // Reset to an empty array or handle as needed
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        // ... other error handling
      }
    };

    fetchData();
  }, [selectedRole]);



  return (
    <div className="pcrpage_container">
    <div className="main-container">
    <CommonNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
          routes={adminRoutes}
        />

      <div className="right-content">
        <div className="lists-container colab-list-container">
          <h4>
            Displaying the student, Instructor details to collaborate
            with them
          </h4>
          <label htmlFor="listSelector"></label>
    <div className="scrollable_container">
      <select
        id="listSelector"
        value={selectedRole}
        onChange={(e) => setSelectedRole(e.target.value)}
      >
        <option value="student">student</option>
        <option value="instructor">instructor</option>
        
      </select>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
              <thead style={{ backgroundColor: '#4CAF50', color: 'white' }}>
          <tr>
          <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>First Name</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Last Name</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Email</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(users) && users.map(user => (
            <tr key={user.id} style={{ backgroundColor: user.id % 2 === 0 ? '#f2f2f2' : 'white' }}>
               <td style={{ border: '1px solid #ddd', padding: '8px' }}><Link to={`/admin/userlogs/${user.id}`}>{user.id}</Link>
</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.fname}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.lname}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${user.email}`}>
                  {user.email}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   </div >
   </div >
 </div >
</div >
  );
}

export default ManageUserActivities;