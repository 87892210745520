import React from "react";
import "../css/global-styles.css";
import { Link } from "react-router-dom";
import { routes } from "../constants";
import Header from "./Header";

const links = [routes.login, routes.home];
function Signup() {
  return (
    <div className="global-styles-ui">
      <div className="pcrpage_container">
        <div className="head">
          <Header links={links} />
          <hr />
        </div>
        <div className="signup-container">
          <form action="https://axs2459.uta.cloud/signup.php" method="post">
            <fieldset id="signinfo" className="filldetails for_phone">
            <br />
              <legend>SIGN UP</legend>
              <br />
              <div id="signinfohorizontal">
              <label htmlFor="username">
               First Name
              </label>
              <input
                type="text"
                id="username" name="fname"
                placeholder="Enter First Name"
                required
              />
              </div>
              <br />
              <div id="signinfohorizontal">
              <label htmlFor="username">
                Last Name
              </label>
              <input
                type="text"
                id="lusername" name="lname"
                placeholder="Enter Last Name"
                required
              />
              </div>
              <br />
              <div id="signinfohorizontal">
              <label htmlFor="email">
                Mail
              </label>
              <input
                type="text"
                id="email" name="email"
                placeholder="Enter your mailID"
                required
              />
              </div>
              <br />
              <div id="signinfohorizontal">
              <label htmlFor="phone">
                Phone
              </label>
              <input
                type="number"
                id="phone" name="phone"
                maxLength={10}
                minLength={10}
                placeholder="Enter Phone"
                required
              />
              </div>
              <br />
              <div id="signinfohorizontal">
              <label htmlFor="roleselection">Who are You?</label>
              <select
                id="roleselection"
                className="signselect"
                name="category"
              >
                <option value="student">Student</option>
                <option value="instructor">Instructor</option>
                <option value="admin">Admin</option>
                <option value="qao">Quality Assurance Officer</option>
                <option value="pc">Program Coordinator</option>
              </select>
              </div>
              <br />
              
              <div className="account-info-signup" style={{margin:"10px", marginLeft:"30px"} }>
                <p>Already have an account?&nbsp;&nbsp;</p>
                <Link to="/login">Login</Link>
              </div>
              <br />
            </fieldset>
           
            <button type="submit">Sign Up</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
