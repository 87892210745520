import React, { useEffect, useState } from "react";
import "../../css/global-styles.css";
import { Link } from "react-router-dom";
import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";
import { useUser } from "../../UserContext";
import axios from 'axios';


function StudentExams() {

  const { userId, setUserId } = useUser();
  // setting global user id to state 
  const [studentId, setStudentId] = useState(userId);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [error, setError] = useState('');
  const [exams, setExams] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [terms, setTerms] = useState([]);  // New state for terms
  const [selectedTerm, setSelectedTerm] = useState('Fall 2023');
  const handleTermChange = (event) => {
    const term = event.target.value;
    setSelectedTerm(term);
    console.log("Selected Term:", selectedTerm);
    fetchEnrolledCourses(term);
  };
  const fetchEnrolledCourses = (term) => {
    axios.post('https://axs2459.uta.cloud/get_courses.php', {
      student_id: userId,
      selected_term: term
    })
    .then(response => {
      if (Array.isArray(response.data)) {
        setEnrolledCourses(response.data);
      } else {
        setEnrolledCourses([]);
      }
    })
    .catch(error => console.error("Error fetching enrolled courses:", error));
  };
  useEffect(() => {
    axios.get('https://axs2459.uta.cloud/term.php')
      .then(response => {
        console.log(response.data); // Logging the fetched terms
        setTerms(response.data);
      })
      .catch(error => console.error("Error fetching terms:", error));
  }, []); // Empty dependency array to ensure this runs only once
  useEffect(() => {
    if (!studentId) {
      setError('User student data not logged in.');
      return;
    }
  });
  useEffect(() => {
    if (selectedTerm) {
      fetchEnrolledCourses(selectedTerm);
    }
  }, [selectedTerm]);
  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    setSelectedCourseId(courseId);
    handleCourseClick(courseId);
  };
  const handleCourseClick = (courseId) => {
    setExams([]);
    axios.post(`https://axs2459.uta.cloud/getstudentexamsinfo.php`, { course_id: courseId })
      .then(response => {
        // handle response
        console.log(response.data);
        setExams(response.data.exams || []);
        // possibly update state with the response data
      })
      .catch(error => {
        // handle error
        console.error("Error fetching exams info: ", error);
      });
  };

  return (
    <div className="sepage-container">
      <div className="semain-container">

        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />

        <div className="secontent">

          <div className="content">
          <div className="term-selector">
        <label htmlFor="term-select"><h4>Choose Term:</h4> </label>
        <select style={{ width: '200px', height: '30px', borderRadius:'5px' }} id="term-select" value={selectedTerm} onChange={handleTermChange}>
          {terms.map(term => (
            <option key={term} value={term}>{term}</option>
          ))}
        </select>
      </div>
      <br />
            {error ? (
              
              <p>{error}</p>
            ) : (
              
              <select style={{ width: '200px', height: '30px' }} id="info" onChange={handleCourseChange} value={selectedCourseId}>
                <option value="">Select a course</option>
                {enrolledCourses.map(course => (
                  <option key={course.ecid} value={course.ecid}>
                    {course.course_name}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div id="exams" style={{ display: "flex", flexWrap: "wrap" }}>

            {exams.length > 0 ? exams.map((exam, index) => (
              <div className="exam-detail" key={index}>
                <h3>{exam.exam_title}</h3>
                <ul>
                  <li>Course ID: {exam.course_id}</li>
                  <li>Exam ID: {exam.exam_id}</li>
                  
                  <li>Total Marks: {exam.total_marks}</li>
                  <li>Format: {exam.format}</li>
                  <li>Number of Questions: {exam.num_questions}</li>
                  <li>Weightage: {exam.weightage}</li>
                  <li>Time: {exam.time}</li>
                </ul>
              </div>
            )) : <p>No exams available.</p>}
          </div>

        </div>
      </div>
    </div>
  );
}

export default StudentExams;
