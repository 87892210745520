import { studentRoutes } from "../../constants";
import StudentNavbar from "./StudentNavbar";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from "../../UserContext";
import { Link } from "react-router-dom";

const DashboardCourseList = () => {
  // importing global variables from context
  const { userId, setUserId } = useUser();
  // setting global user id to state 
  const [studentId, setStudentId] = useState(userId);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [error, setError] = useState('');
  const [terms, setTerms] = useState([]);  // New state for terms
  const [selectedTerm, setSelectedTerm] = useState('Fall 2023');
  const handleTermChange = (event) => {
    const term = event.target.value;
    setSelectedTerm(term);
    console.log("Selected Term:", selectedTerm);
    fetchEnrolledCourses(term);
  };
  const fetchEnrolledCourses = (term) => {
    axios.post('https://axs2459.uta.cloud/get_courses.php', {
      student_id: userId,
      selected_term: term
    })
    .then(response => {
      if (Array.isArray(response.data)) {
        setEnrolledCourses(response.data);
      } else {
        setEnrolledCourses([]);
      }
    })
    .catch(error => console.error("Error fetching enrolled courses:", error));
  };
  useEffect(() => {
    axios.get('https://axs2459.uta.cloud/term.php')
      .then(response => {
        console.log(response.data); // Logging the fetched terms
        setTerms(response.data);
      })
      .catch(error => console.error("Error fetching terms:", error));
  }, []); // Empty dependency array to ensure this runs only once
  useEffect(() => {
    if (selectedTerm) {
      fetchEnrolledCourses(selectedTerm);
    }
  }, [selectedTerm]); // Dependency array with selectedTerm
    
  useEffect(() => {
    if (!studentId) {
      setError('User student data not logged in.');
      return;
    }
   
  });

  return (
    <div className="page-container">
      <div className="main-container">
        <StudentNavbar wrapperClass="left-navbar" menuItemClass="nav-button" />
        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
         <div className="term-selector">
        <label htmlFor="term-select"><h3>Choose Term: </h3></label>
        <br />
        <select style={{ width: '200px', height: '30px', borderRadius:'5px' }} id="term-select" value={selectedTerm} onChange={handleTermChange}>
          {terms.map(term => (
            <option key={term} value={term}>{term}</option>
          ))}
        </select>
      </div>
        <div className="content">
          
          {error ? (
            <p>{error}</p>
          ) : (
            enrolledCourses.map(course => (
              <div className="course-box" key={course.ecid}>
                <Link to={`/course/${course.ecid}`}>
                <h4>{course.course_name}</h4>
                 
                </Link>
                
              </div>
            ))
          )}
        </div>
        </div>
      </div>
    </div>
  );
  
};

export default DashboardCourseList;