import React, { useState, useEffect } from "react";
import InstructorNavbar from "./InstructorNavbar";
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from "../../UserContext";
import Button from "@mui/material/Button";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
interface Course {
  cid: number;
  course_name: string;
  passPercentage: number;
}
interface Student {
  id: string;
  fname: string;
  lname: string;
  email: string;
  grade?: string; // Optional field for grade
}

interface Exam {
  exam_id: number;
  mean: number;
  low: number;
  high: number;
  total_marks: number;
  date: string;
  students: number;
}
export default function InstructorDashboard() {
  const [open, setOpen] = useState(false);
  const { userId, setUserId } = useUser();
  const [courses, setCourses] = useState<Course[]>([]);
  const [instructorName, setInstructorName] = useState("");
  const [exams, setExams] = useState<Exam[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
  const [selectedExamData, setSelectedExamData] = useState<any[]>([]);
  const [students, setStudents] = useState([]);
  const [rawStudentResponse, setRawStudentResponse] = useState("");
  //grades
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [grade, setGrade] = useState("");
  const [isGradeModalOpen, setIsGradeModalOpen] = useState(false);

  const handleRowClick = (student) => {
    setSelectedStudent(student);
    setGrade(student.grade || ""); // Set existing grade if available
    setIsGradeModalOpen(true);
  };

  const handleGradeChange = (event) => {
    setGrade(event.target.value);
  };

  const handleSubmitGrade = () => {
    if (!selectedStudent || !selectedCourseId) {
      console.error("Missing student or course information");
      return;
    }

    // Prepare the data to be sent
    const formData = {
      student_id: selectedStudent.id,
      fname: selectedStudent.fname,
      lname: selectedStudent.lname,
      email: selectedStudent.email,
      course_id: selectedCourseId,
      grade: grade,
    };

    // Make the API call
    axios
      .post("https://axs2459.uta.cloud/gradesforcourse.php", formData)
      .then((response) => {
        // Handle the response from the server
        console.log("Grade submitted successfully:", response.data);
        // Additional actions after successful submission
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error submitting grade:", error);
      });

    // Close the modal after submission
    setIsGradeModalOpen(false);
  };
  const fetchGradesForCourse = (courseId) => {
    axios
      .post("https://axs2459.uta.cloud/fetchgrades.php", {
        course_id: courseId,
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          // Create a map of student IDs to grades
          const gradeMap = response.data.reduce((acc, gradeInfo) => {
            acc[gradeInfo.student_id] = gradeInfo.grade;
            return acc;
          }, {});

          // Update the students' state with the new grades
          setStudents((prevStudents) => {
            if (Array.isArray(prevStudents)) {
              return prevStudents.map((student) => ({
                ...student,
                grade: gradeMap[student.id] || student.grade,
              }));
            } else {
              console.error("prevStudents is not an array:", prevStudents);
              return prevStudents; // Return the original value to avoid breaking the application
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching grades:", error);
      });
  };

  useEffect(() => {
    if (userId) {
      axios
        .post("https://axs2459.uta.cloud/profile.php", { user_id: userId })
        .then((response) => {
          // Check if there is an error in the response
          if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            // Assuming the response data has the user's first and last name
            const { fname, lname } = response.data;
            setInstructorName(`${fname} ${lname}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching instructor information:", error);
        });
    }
  }, [userId]); // This effect runs when the userId changes.
  //const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Assuming you fetch the instructor's ID and set it in the userId state
    if (userId) {
      axios
        .post("https://axs2459.uta.cloud/coursesinstructor.php", {
          instructorName: instructorName,
        })
        .then((response) => {
          if (Array.isArray(response.data)) {
            setCourses(response.data);
          } else if (response.data.error) {
            console.error("Error:", response.data.error);
          } else {
            console.error("Unexpected response:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching courses:", error);
        });
    }
  }, [userId, instructorName]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };
  const handleExamClick = (exam: Exam) => {
    // Prepare the data for the bar chart
    const chartData = [
      { name: "Mean", value: exam.mean },
      { name: "Low", value: exam.low },
      { name: "High", value: exam.high },
    ];
    setSelectedExamData(chartData);
  };
  const handleCourseClick = (cid: number) => {
    setSelectedCourseId(cid);

    console.log(`Selected Course ID: ${cid}`); // Print the selected course ID

    // Fetch students based on the selected course ID
    axios
      .post(
        "https://axs2459.uta.cloud/fetchstudents.php",
        JSON.stringify({ course_id: cid }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Students response:", response); // Print the response for debugging
        if (Array.isArray(response.data)) {
          fetchGradesForCourse(selectedCourseId);
          // Now make another request to get detailed information of these students
          axios
            .post("https://axs2459.uta.cloud/getstudnetslist.php", {
              student_ids: response.data.map((s) => s.student_id),
            })
            .then((detailedResponse) => {
              // Update the state with detailed information
              setStudents(detailedResponse.data);
            })
            .catch((error) => {
              console.error(
                "Error fetching detailed student information:",
                error
              );
            });
        } else {
          console.error("Data is not an array", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching student IDs:", error);
      });
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="page-container">
      {/* MAIN CONTENT = NO HEADER AND FOOTER */}
      <div className="main-container">
        {/* LEFT NAVBAR */}
        <InstructorNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
        />

        {/* COURSE BOXES */}
        <div>
          {/* <button
            type="button"
            className="mark button primary-button"
            onClick={handleModalOpen}
          >
            Add course
          </button> */}
          <>
          <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: 10,
          }}
        >
          <input placeholder="course name" style={{ height: "30px" }} />
          <input
            placeholder="course content"
            style={{ height: "30px" }}
          />
          <button
            style={{ width: 100, alignSelf: "center", height: 30 }}
          >
            Add course
          </button>
        </div>
      </Box>
    </Modal>
          </>

          <div className="content">
            {courses.map((course, index) => (
              <div
                className="course-box"
                key={course.cid}
                onClick={() => handleCourseClick(course.cid)}
              >
                <a href="#">
                  Course {index + 1}
                  <br />
                  <br />
                  <br />
                  {course.course_name}
                </a>
              </div>
            ))}
            {selectedCourseId && students.length > 0 && (
              <table
                style={{
                  width: "80%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
              >
                <thead style={{ backgroundColor: "#4CAF50", color: "white" }}>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        borderTopLeftRadius: "10px",
                        padding: "8px",
                      }}
                    >
                      Student ID
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      First Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Last Name
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Email
                    </th>
                    {/* <th style={{ border: '1px solid #ddd', padding: '8px' }}>Grade</th> */}
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Add Grade
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        {student.id}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {student.fname}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {student.lname}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {student.email}
                      </td>
                      {/* <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{student.grade || 'Not Set'}</td> */}
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            color: "#2a84d8",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the event from bubbling up to the row
                            handleRowClick(student);
                          }}
                        >
                          Add Grade
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {isGradeModalOpen && (
            <Modal
              open={isGradeModalOpen}
              onClose={() => setIsGradeModalOpen(false)}
            >
              <Box sx={style}>
                <h2>Grade Student</h2>
                <p>Student ID: {selectedStudent.id}</p>
                <p>
                  Student Name:{" "}
                  {`${selectedStudent.fname} ${selectedStudent.lname}`}
                </p>
                <p>Course ID: {selectedCourseId}</p>
                <FormControl fullWidth style={{ margin: "10px 0" }}>
                  <InputLabel>Grade</InputLabel>
                  <Select
                    value={grade}
                    label="Grade"
                    onChange={handleGradeChange}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="C">C</MenuItem>
                    <MenuItem value="D">D</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitGrade}
                  style={{ marginTop: "10px" }}
                >
                  Submit
                </Button>
              </Box>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}
