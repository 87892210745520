import React, { useState, useEffect } from "react";
import AdminNavbar from "./AdminNavbar";
import { adminRoutes } from "../../constants";
import CommonNavbar from "../Shared/CommonNavbar";

const AdminContact = () => {
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    fetch("https://axs2459.uta.cloud/fetchcontacts.php")
      .then((response) => response.json())
      .then((data) => setContactList(data))
      .catch((error) => console.error("Error fetching contact data:", error));
  }, []);

  // Function to handle contact deletion
  const handleDelete = (contactId) => {
    fetch(`https://axs2459.uta.cloud/deletecontact.php?id=${contactId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then(() => {
        setContactList(
          contactList.filter((contact) => contact.id !== contactId)
        );
      })
      .catch((error) => console.error("Error deleting contact:", error));
  };

  return (
    <div className="pcrpage_container">
      <div className="main-container">
        <CommonNavbar
          wrapperClass="left-navbar"
          menuItemClass="nav-button"
          routes={adminRoutes}
        />
        <div className="right-content">
          <div className="PC_container-card-grid">
            <div className="card-row-enquiries">
              {contactList.map((contact, index) => (
                <div className="card-enquiries" key={index}>
                  <div className="student-info-enquiries">
                    <p>Name: {contact.name}</p>
                    <p>Email: {contact.email}</p>
                    <p>Message: {contact.message}</p>
                    <button type="submit" onClick={() => handleDelete(contact.id)}>
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminContact;
